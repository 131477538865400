export const facultyPublicationData = [
["Dr. S. S. Rathod", 0, 0, 1, 1, 5, 4, 8, 5, 13],
["Dr. R. G. Sutar", 0, 2, 0, 1, 3, 0, 4, 1, 9],
["Dr. D. C. Karia", 0, 5, 0, 0, 8, 1, 5, 1, 18],
["Prof. K. T. Talele", 0, 2, 0, 0, 5, 0, 2, 0, 9],
["Prof. N. A. Bhagat", 0, 4, 0, 0, 3, 0, 3, 0, 10],
["Prof. P. V . Kasambe", 0, 1, 0, 2, 5, 1, 3, 3, 9],
["Prof. P. H. Shah", 2, 5, 0, 0, 6, 0, 5, 2, 16],
["Prof. M. R. Bansode", 2, 2, 0, 0, 6, 0, 4, 2, 12],
["Prof. G.T. Haldankar", 0, 2, 0, 0, 3, 0, 3, 0, 8],
["Prof. Priya Chimurkar", 0, 5, 0, 0, 4, 1, 8, 1, 17],
["Prof. Najib Ghatte", 0, 2, 0, 1, 6, 0, 3, 1, 11],
["Dr. Y . S. Rao", 2, 1, 0, 3, 2, 2, 4, 7, 7],
["Dr. R. R. Sawant", 0, 0, 0, 3, 1, 2, 2, 5, 3],
["Prof. D. D. Ambawade", 0, 1, 0, 0, 8, 0, 16, 0, 25],
["Dr. Reena Sonkusare", 1, 2, 0, 2, 1, 2, 8, 5, 11],
["Prof. Manish Parmar", 0, 1, 0, 0, 0, 0, 6, 0, 7],
["Dr. Sukanya Kulkarni", 0, 0, 0, 1, 5, 1, 6, 2, 11],
["Dr. Sujata Kulkarni", 2, 2, 0, 1, 2, 0, 4, 3, 8],
["Prof. Anand Mane", 2, 0, 0, 0, 0, 0, 1, 2, 1],
["Prof. Vijaya Kamble", 0, 0, 0, 1, 0, 0, 2, 1, 2],
["Prof. Milind Paraye", 0, 2, 0, 0, 0, 0, 3, 0, 5],
["Prof. Pallavi Malame", 0, 0, 0, 1, 0, 1, 0, 2, 0],
["Dr. Amol Deshpande", 0, 3, 0, 1, 4, 2, 6, 3, 13],
["Prof. Sneha Weakey", 0, 0, 0, 0, 3, 0, 3, 0, 6],
];

export const facultyPublicationCitations = [
["Dr. S. S. Rathod", 637, 12, 21, 75, 364, 10],
["Dr. R. G. Sutar", 102, 6, 4, 20, 56, 5],
["Dr. D. C. Karia", 491, 11, 17, 71, 255, 9],
["Prof. K. T. Talele", 325, 12, 12, 43, 145, 8],
["Prof. N. A. Bhagat", 99, 4, 4, 18, 34, 4],
["Prof. P. V . Kasambe", 120, 6, 3, 16, 65, 4],
["Prof. P. H. Shah", 110, 5, 3, 21, 47, 3],
["Prof. M. R. Bansode", 77, 4, 3, 15, 46, 4],
["Prof. G.T. Haldankar", 45, 3, 1, 12, 16, 2],
["Prof. Priya Chimurkar", 131, 6, 6, 13, 56, 4],
["Prof. Najib Ghatte", 43, 4, 1, 8, 9, 2],
["Dr. Y . S. Rao", 520, 9, 8, 41, 201, 7],
["Dr. R. R. Sawant", 430, 10, 11, 30, 284, 7],
["Prof. D. D. Ambawade", 561, 12, 19, 49, 291, 10],
["Dr. Reena Sonkusare", 312, 10, 10, 7, 17, 2],
["Prof. Manish Parmar", 192, 6, 4, 15, 124, 5],
["Dr. Sukanya Kulkarni", 100, 6, 1, 22, 62, 4],
["Dr. Sujata Kulkarni", 274, 7, 6, 17, 28, 4],
["Prof. Anand Mane", 12, 8, 2],
["Prof. Vijaya Kamble", 208, 4, 3, 16, 120, 3],
["Prof. Milind Paraye", 23, 3, 0, 7, 12, 2],
["Prof. Pallavi Malame", 33, 2, 1, 2, 3, 1],
["Dr. Amol Deshpande", 301, 8, 7, 15, 44, 5],
["Prof. Sneha Weakey", 19, 1, 1, 3, 19, 2]
];
