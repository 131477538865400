export const IMAGES = [
  { src: "https://i.ibb.co/PCB1hj8/11.png", thumbnail: "https://i.ibb.co/PCB1hj8/11.png" },
  { src: "https://i.ibb.co/Jk26kNQ/12.png", thumbnail: "https://i.ibb.co/Jk26kNQ/12.png" },
  { src: "https://i.ibb.co/rk2tqmV/13.png", thumbnail: "https://i.ibb.co/rk2tqmV/13.png" },
  { src: "https://i.ibb.co/2q4wctQ/14.png", thumbnail: "https://i.ibb.co/2q4wctQ/14.png" },
  { src: "https://i.ibb.co/c3f3wLJ/15.png", thumbnail: "https://i.ibb.co/c3f3wLJ/15.png" },
  { src: "https://i.ibb.co/99RFhtZ/16.png", thumbnail: "https://i.ibb.co/99RFhtZ/16.png" },
  { src: "https://i.ibb.co/xJKzC3D/17.png", thumbnail: "https://i.ibb.co/xJKzC3D/17.png" },
  { src: "https://i.ibb.co/myJJYCb/18.png", thumbnail: "https://i.ibb.co/myJJYCb/18.png" },
  { src: "https://i.ibb.co/kQZpvpG/19.png", thumbnail: "https://i.ibb.co/kQZpvpG/19.png" }
];