export const IMAGES = [

  {
    src: "https://i.ibb.co/Gkx0yrQ/Picture2.png",
    // humbnail: "httpst://i.ibb.co/Yc4dyMr/106_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/2KzxXQw/Picture3.png",
    thumbnail: "https://i.ibb.co/6rHKxg8/105_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/ypPbwGz/Picture4.png",
    thumbnail: "https://i.ibb.co/2sTBPdX/102_thumb.jpg",
  },
 
  {
    src: "https://i.ibb.co/stMVyYD/Picture6.png",
    thumbnail: "https://i.ibb.co/DzywQXn/103_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/ZfPMsxD/Picture7.png",
    thumbnail: "https://i.ibb.co/Q6sSWpY/104_thumb.jpg",
  },
  {
    src: "https://i.ibb.co/DV4s9YV/Picture8.png",
  },
  {
    src: "https://i.ibb.co/GtwWJqD/Picture9.png",
  },
  {
    src: "https://i.ibb.co/j5fs1W6/Picture1.png",
  },
];
