export const IMAGES = [
  { src: "https://i.ibb.co/ZfYDKtX/Whats-App-Image-2024-03-20-at-11-03-55-a6b35892.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/dLh4gbL/Whats-App-Image-2024-03-20-at-11-03-55-3b0e2866.jpg", thumbnail: "" },
  {
    src: "https://i.ibb.co/7QcKWHb/image-001.jpg",
    thumbnail: "https://i.ibb.co/7QcKWHb/image-001.jpg",
  },
  {
    src: "https://i.ibb.co/ZzYKz9Z/image-003.jpg",
    thumbnail: "https://i.ibb.co/ZzYKz9Z/image-003.jpg",
  },
  // {
  //   src: "https://i.ibb.co/y6Tqrhy/image-004.jpg",
  //   thumbnail: "https://i.ibb.co/y6Tqrhy/image-004.jpg",
  // },
  {
    src: "https://i.ibb.co/0Yt01Yb/image-006.jpg",
    thumbnail: "https://i.ibb.co/0Yt01Yb/image-006.jpg",
  },
  {
    src: "https://i.ibb.co/Ksz0N8L/image-007.jpg",
    thumbnail: "https://i.ibb.co/Ksz0N8L/image-007.jpg",
  },
  {
    src: "https://i.ibb.co/rpqVh4K/image-009.jpg",
    thumbnail: "https://i.ibb.co/rpqVh4K/image-009.jpg",
  },
  {
    src: "https://i.ibb.co/6ZpJn36/Screenshot-2024-03-24-160523.png",
    thumbnail: "https://i.ibb.co/6ZpJn36/Screenshot-2024-03-24-160523.png",
  },
];
