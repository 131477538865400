export const IMAGES = [
  {
    src: "https://i.ibb.co/600Y9BM/Whats-App-Image-2024-04-29-at-13-01-43-4b2e92b6.jpg",
    thumbnail:
      "https://i.ibb.co/600Y9BM/Whats-App-Image-2024-04-29-at-13-01-43-4b2e92b6.jpg",
  },
  {
    src: "https://i.ibb.co/qmYLQ7V/Whats-App-Image-2024-04-29-at-13-01-41-2df9c7c8.jpg",
    thumbnail:
      "https://i.ibb.co/qmYLQ7V/Whats-App-Image-2024-04-29-at-13-01-41-2df9c7c8.jpg",
  },
];
