export const IMAGES = [
  { src: "https://i.ibb.co/pP9vyLZ/image-1.png", thumbnail: "" },
    { src: "https://i.ibb.co/mNVbJzN/image.png", thumbnail: "" },
  { src: "https://i.ibb.co/zfLPhcj/image.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/fxLXdbX/1.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/Ss4ty2G/2.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/6nWTZwC/3.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/zXwMW99/4.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/4KtD5Sk/Whats-App-Image-2024-04-30-at-10-59-30-905670ce.jpg", thumbnail: ""}
  
];
