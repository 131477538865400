export const IMAGES = [
  {
    src: "https://i.ibb.co/xjbpGpN/3041181.png",
    thumbnail: "",
  },
  {
    src: "https://i.ibb.co/KmMgyNQ/3041185.png",
    thumbnail: "",
  },
];
