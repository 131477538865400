export const IMAGES = [
  { src: "https://i.ibb.co/gZzqGD4/image-3.png", thumbnail: "" },
  { src: "https://i.ibb.co/GVp98DH/image-2.png", thumbnail: "" },
  { src: "https://i.ibb.co/G5y4SyG/image-1.png", thumbnail: "" },
  { src: "https://i.ibb.co/tYBWLv0/image.png", thumbnail: "" },
  {
    src: "https://i.ibb.co/xSJvkKZ/image.jpg",
    thumbnail: "",
  },
  {
    src: "https://i.ibb.co/vq6hc7j/1.jpg",
    thumbnail: "",
  },
  {
    src: "https://i.ibb.co/nQJVBcb/2.jpg",
    thumbnail: "",
  },
];
