export const IMAGES = [
  {
    src: "https://i.ibb.co/jD8GXfc/Whats-App-Image-2024-04-30-at-10-59-28-e3d9f64a.jpg",
    thumbnail:
      "https://i.ibb.co/jD8GXfc/Whats-App-Image-2024-04-30-at-10-59-28-e3d9f64a.jpg",
  },
  {
    src: "https://i.ibb.co/R33zmWz/Whats-App-Image-2024-04-30-at-10-59-29-c2eee04d.jpg",
    thumbnail:
      "https://i.ibb.co/R33zmWz/Whats-App-Image-2024-04-30-at-10-59-29-c2eee04d.jpg",
  },
];
